.avatar-container{
    max-width: 862px;
    width: 100%;
    margin-top: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 24px;
    grid-column-gap: 16px;
    font-weight: 600;
    color: #E7E1D9;
    /* font-size: 16px;
    line-height: 20px; */
}
.avatar-container .avatar-item-container:hover{
    background-color: #2A2A32;
    cursor: pointer;
}
.avatar-container .avatar-item-container .avatar-img-container {
    width: 100%;
    padding-bottom: 100%;
    /* max-width: 198px; */
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.avatar-container .avatar-item-container .avatar-img-container >img {
    width: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
.avatar-container .avatar-item-container{
    border: 1px solid #3b3e42;
    /* padding: 12px 0px; */
    /* padding-bottom: 14px; */
}
.avatar-container .avatar-item-container .avatar__icon-id{
    position: absolute;
    top: 12px;
    left:12px;
    background-color: red;
    color:#E7E1D9;
    border-radius: 10%;
    padding-left: 3px;
    padding-right: 3px;
    z-index: 1;
}
.avatar-container .avatar-item-container .avatar__amount{
    position: absolute;
    top: 12px;
    right:12px;
    color:#E7E1D9;
    z-index: 1;
}
.avatar-container .avatar-item-container .price-container {
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.3);
}
.avatar-container .avatar-item-container .war-icon{
    object-fit: cover;
    width: 16px;
    height: 16px;
}
@media only screen and (max-width: 1280px){
    .avatar-container{
        padding-left: 20px;
        padding-right: 20px;
    }
    /* .gta-openall{
        padding-left: 20px;
        padding-right: 20px;
    } */
}
@media only screen and (max-width: 750px) {
    .avatar-container
    {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 12px;
    }
}
@media only screen and (max-width: 560px){
    .avatar-container{
        grid-template-columns: 1fr;
        margin-top: 14px;
    }
}