
@import url('https://fonts.googleapis.com/css family=Titillium+Web:300,400,400i,600,600i,700');

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  
}
body > iframe {
  display: none;
}
#root:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url('./assets/home/bg-section4.jpeg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #130C09;
}

#root {
  /* background: url('./assets/home/bg-section4.png');
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  background-color: #130C09;
  background-position: center; 
  background-attachment: fixed;
  -webkit-background-size: cover;  */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
  tablet: 750px;
  tablet-small: 560px;
  mobile: 320px;
*/