.social-fixed {
    position: fixed;
    top:50%;
    right:0%;
    transform: translateY(-50%);
    z-index: 10;
    width:35px;
    overflow: visible;
}
.social-fixed .social {
    width:120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:5px;
    background-color: black;
    margin:5px 0px;
    /* transform: translateX(60px); */
    transition:all .3s;
    border: 1px solid #E3BC5B;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}
.social-fixed .social span {
    color: #E3BC5B;
}
.social-fixed .social:hover {
    transform: translateX(-60px);
}
.social-fixed .social > img {
    width: 25px;
    margin-right: 7px;
}
.social-fixed .social >svg{
    margin-right: 7px;
}
.social-fixed .social .link-qr{
    position: absolute;
    top: 15px;
    left: -15px;
    transform: translateX(-100%);
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    background-image: url('../../assets/home/scan_box.png');
    background-size: 100% 100%;
}
.social-fixed .social:hover .link-qr{
    display: flex;
}
.social-fixed .social .link-qr .qrr{
    width: 330px;
    height: 330px;
}
.social-fixed .social .link-qr .game-link{
    color: #0683b1;
    margin-top: 4px;
}
.social-fixed .social .link-qr .scan-text{
    color:white;
    font-size: 22px;
    margin-top: 4px;
}
.social-fixed .social span {
    font-size: 13px;
}
.social-fixed .social .nav-hover{
    position: absolute;
    padding-top:10px;
    top:100%;
    width:180px;
    left:0;
    margin-left:0px;
    transform: translateX(-40%);
    display: none;
}
.social-fixed .social:hover .nav-hover{
    display: block;
}
.social-fixed .social:hover .nav-hover ul{
    background: url('../../assets/bg__dropdown.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left:0px;
    font-size: 15px;
    text-align: center;
    padding: 15px 0px;
    cursor: pointer;
    transition: all .3s;
}
.social-fixed .social .nav-hover li{
    color: #2C1B05;
    
}
.social-fixed .social .nav-hover li:hover{
    color:#DCB85C;
}
.social-fixed .social.show-560{
    display: none;
}
@media only screen and (max-width: 600px){
    .social-fixed .social  .link-qr .qrr{
        width: 280px;
        height: 280px;
    }
}
@media only screen and (max-width: 560px) {
    .social-fixed .social.show-560{
        display: flex;
    }
    .social-fixed .social:hover .link-qr{
        display: none;
    }
}