@keyframes moveInOpacity {
    from {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
    to {
        opacity:1;
        visibility: visible;
    }
}
@keyframes moveOutOpacity {
    from {
        opacity: 1
    }
    to {
        opacity:0;
        visibility: hidden;
    }
}
@keyframes moveInOpacityImage {
    from {
        opacity: 0;
        visibility: hidden;
        display: none;
        transform: scale(0.5);
    }
    to {
        opacity:1;
        visibility: visible;
        transform: scale(1);
    }
}
@keyframes moveOutOpacityImage {
    from {
        opacity: 1;
    }
    to {
        opacity:0;
        visibility: hidden;
        transform: scale(0.5);
    }
}
@keyframes moveInOpacityNoneDisplay {
    from {
        opacity: 0;
        visibility: hidden;
        position: fixed;
    }
    to {
        opacity:1;
        visibility: visible;
        position: relative;
    }
}
@keyframes moveOutOpacityNoneDisplay {
    from {
        display: block;
    }
    to {
        display: none;
    }
}

@keyframes moveInCircle {
    0% {
        width: 0;
        height:0;
        border-radius: 50%;
        opacity: 0;
    }
    50% {
        width:20px;
        height:100vh;
    }
    100% {
        width:100vw;
        height: 100vh;
        border-radius: 0%;
        opacity: 1;
    }
}

@keyframes moveOutCircle {
    0% {
        width:100vw;
        height: 100vh;
        border-radius: 0%;
        opacity: 1;
        
    }
    50% {
        width:20px;
        height:100vh;
    }
    100% {
        width: 0;
        height:0;
        border-radius: 50%;
        opacity: 0;
    }
}
@keyframes shaking {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-2deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}