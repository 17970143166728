.scroll-btn {
    position: fixed;
    z-index: 1000;
    width:50px;
    height:50px;
    border-radius: 5px;
    background-color: black;
    color: #E3BC5B;
    bottom: 20px;
    right:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(200px);
    transition: all .3s;
    border:1px solid #E3BC5B;
}
.scroll-btn svg {
    font-size: 30px;
}