.navbar { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width:100%;
    height:65px;
    color:white;
    z-index: 101;
    box-sizing: border-box;
    background:linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 100%), url('../../assets/nav-asset/bg.png');
    background-size: 100% 100%;
    background-position: center;
    font-weight: 600;
    color:rgba(255, 239, 216, 1);
    position:fixed;
    top:0;
    left:0;
}
.navbar .account-address {
    width: 50px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar .icon-menu-bar {
    font-size: 35px;
    margin-left:10px;
    display: none;
    cursor: pointer;
}
.navbar .bnb-logo {
    width:30px;
}
.navbar .profile {
    font-size:15px;
    font-family: 'Montserrat';
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-weight: 600;
}
.navbar img {
    width:150px;
}
.navbar .container-md {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex:1;
}
.navbar .container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.navbar .list-nav {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin:0;
    padding:0;
}
.navbar .list-nav .nav-link {
    margin-left:32px;
    text-transform: capitalize;
    transition: all .3s;
    position: relative;
}

.navbar .list-nav .nav-link .nav-hover {
    position: absolute;
    padding-top:10px;
    top:100%;
    width:180px;
    left:0;
    margin-left:0px;
    transform: translateX(-40%);
    display: none;
}

.navbar .list-nav .nav-link .nav-hover ul, .navbar .account-hover__container .account-hover ul {
    background: url('../../assets/bg__dropdown.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #2C1B05;
}
.navbar .list-nav .nav-link .nav-hover  li, .navbar .account-hover__container .account-hover li, .navbar .account-hover__container .account-hover div {
    margin-left:0px;
    font-size: 15px;
    text-align: center;
    padding: 15px 0px;
    cursor: pointer;
    transition: all .3s;
    
}
.navbar .list-nav .nav-link .nav-hover  li:hover, .navbar .account-hover__container .account-hover li:hover, .navbar .account-hover__container .account-hover div:hover {
    color:#DCB85C;
}

.navbar .list-nav .nav-link:hover .nav-hover{
    display: block;
} 
.navbar .list-nav .nav-link:hover {
    color:#DCB85C;
}
.navbar .btn-primary {
    font-weight: 600;
    font-size: 15px;
    width: 150px;
}

.navbar .btn-secondary {
    font-weight: 600;
    font-size: 15px;
    width: 150px !important;
}

.navbar .account-hover__container{
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}
.navbar .account-hover__container .account-hover {
    padding-top:10px;
    position: absolute;
    top: 100%;
    right: 0;
    width:180px;
    display: none;
}
.navbar .profile-container:hover .account-hover{
    display: block;
}
.navbar .profile:hover .btn-dropdown{
    color:#DCB85C;
}
.navbar .avatar {
    width: 44px;
    height:44px;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
}
@media only screen and (max-width: 1280px) {
    .navbar  {
        padding: 0px 20px;
    }
}
@media only screen and (max-width: 1260px) {
    .navbar .list-nav .nav-link .nav-hover .nav-link.show-mobile{
        display: block;
    }
    .navbar .list-nav .nav-link.hidden-mobile {
        display: none;
    }
    .navbar .list-nav .nav-link {
        margin-left:22px;
    }
}
@media only screen and (max-width: 1050px) {
    .navbar .list-nav  {
        display: none;
    }
    .navbar .list-nav.show-mobile  {
        display: block;
    }
    .navbar .icon-menu-bar {
        display: block;
    }
}
@media only screen and (max-width: 560px){
    .navbar .list-nav.hidden-560{
        display: none;
    }
    .navbar .list-nav .nav-link .nav-hover {
        left:-50px;
    }
    .navbar .hidden-btn-mobile{
        display: none;
    }
    .navbar .profile-container:hover .account-hover{
        display: none;
    } 
    /* .navbar .profile{
        padding-right: 14px;
        padding-left: 14px;
    } */
    .navbar .bnb-logo {
        width:20px;
    }
}
@media only screen and (max-width: 460px) {
  
    /* .navbar .bnb-info{
        display: none;
    } */
    
    .navbar .account-address{
        font-size: 13px;
        display: none;
    }
     .navbar .profile{
        padding-right: 26px;
        padding-left: 14px;
    }
}