.authenticate-form {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
}
.authenticate-form .btn-primary {
    cursor: pointer;
}
.authenticate-form .form {
    background: #131317;
    width: 90%;
    max-width:500px;
    padding:20px;
    display: flex;
    flex-direction: column;

}
.authenticate-form .form svg {
    cursor: pointer;
}
.authenticate-form .form-error {
    color:#EF3131;
    text-align: center;
    font-size: 14px;
}
.authenticate-form .form .input-field {
    color:#E7E1D9;
    display: flex;
    flex-direction: column;
    margin-top:15px;;
    font-size: 14px;
    font-weight: 600;
}
/* .authenticate-form .form .input-field .filter-search input {
    flex:1;
    border: 1px solid #51555A;
    background-color: transparent;
    outline: none;
    padding: 10px;
    margin-top: 5px;
} */
.authenticate-form .form .input-field .filter-search {
    border: solid 1px #51555A;
    padding: 12px 76px 12px 12px;
}
.authenticate-form .form .input-field .filter-search input{
    background-color: transparent !important;
    border: none;
    outline: none;
    flex: 1;
}
.authenticate-form .form .input-field .filter-search input:focus{
    border: none;
    outline: none;
    background-color: transparent;
}
.authenticate-form .form .input-field .filter-search input::-webkit-input-placeholder { /* Edge */
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51555A;
    background-color: transparent;
  }
  .authenticate-form .form .input-field .filter-search input:-webkit-autofill, .authenticate-form .form .input-field .filter-search input:-webkit-autofill:hover, .authenticate-form .form .input-field .filter-search input:-webkit-autofill:active, .authenticate-form .form .input-field .filter-search input:-webkit-autofill:focus{
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  .authenticate-form .form .input-field .filter-search input:autofill{
    background-color: transparent !important;
    background-image: none !important;
  }
  .authenticate-form .form .input-field .filter-search input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51555A;
    background-color: transparent;
  }
  
  .authenticate-form .form .input-field .filter-search input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51555A;
    background-color: transparent;
  }
  .authenticate-form .form .input-field-icon{
      opacity: 0.5;
  }
.authenticate-form .form .link-form {
    color:#E7E1D9;
    margin:5px 0px;
    display: block;
    font-size: 13px;
}
.authenticate-form .form .link-form b {
    color:#E7C060;
    font-weight: 500;
    cursor: pointer;
}
.authenticate-form .form .btn-primary {
    align-self: center;
}
.authenticate-form .form .btn {
    width: 120px;
}
/* .authenticate-form .login-form {

} */
.authenticate-form .title-container {
    font-size: 24px;
    color: #CCAA8B;
    font-family: 'Chakra Petch';
    font-weight: 600;
    align-items: center;
    letter-spacing: 8px;
}
.authenticate-form .register-option{
    padding: 30px;
    background-color: #2A2A2D;
}
.authenticate-form .register-option .title{
    font-size: 1.5rem;
    line-height: 1.875rem;
    text-align: center;
    color: #fff;
    margin-bottom: 1rem;
    text-transform: uppercase;
}
.authenticate-form .register-option .subtitle{
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #c6c9dc;
    padding: 2em;
    display: flex;
    flex-direction: column;
}
.authenticate-form .register-option .option-close{
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 2px;
}
.authenticate-form .btn-skip{
    color: #E7C060;
    text-decoration: underline;
    font-size: 14px;
    margin-top: 12px;
    text-align: center;
    cursor: pointer;
}
.authenticate-form .register-option .btn-1{
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
    padding: 1rem;
    height: 3.5rem;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 0px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bolder;
    font-family: inherit;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    background-image: url('../../assets/home/btn-primary.png');
    background-size:100% 100%;
    color:rgba(44, 27, 5, 1);
    font-family: 'Titillium Web', sans-serif;
    position:relative;
    text-align: center;
}
.authenticate-form .register-option .btn-2{
    border-radius: 0.25rem;
    border: solid 1px #413ded;
    background: transparent;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
    padding: 1rem;
    height: 3.5rem;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 0px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bolder;
    font-family: inherit;
    font-size: 18px;
    line-height: 24px;
    background-image: url('../../assets/home/btn-secondary.png');
    background-size:100% 100%;
    color: #DECFC0;
    margin-top: 8px;
    text-align: center;
}