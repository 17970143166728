.qr-form {
    position: fixed;
    top:0;
    left:0;
    z-index: 100000;
    width: 100vw;
    height:100vh;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qr-form .qr-code-container {
    width: 95%;
    max-width:420px;
    border-radius: 10px;
    background-color: #fff;
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.qr-form .qr-code-container .title {
    font-size: 32px;
    font-family: 'Chakra Petch';
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 600;
    color:#E6BE5E;
    margin-bottom: 5px;
}
.qr-form .qr-code-container .qr-token {
    margin-top: 20px;
    border-radius: 5px;
    padding:10px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .3s;
    cursor: pointer;
}
.qr-form .qr-code-container .qr-container {
    padding:30px;
    /* background: url('../../assets/qr-border.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    
}
.qr-form .qr-code-container .qr-token:hover {
    background-color: rgba(0,0,0,0.2);
}
.qr-form .qr-code-container .token{
    width: 90%;
    font-size: 12px;
    word-wrap: break-word;
}
@media only screen and (max-width:560px) {
    .hidden-mobile{
        display: none;
    }}
