 .tour-banner{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 35vw;
    z-index: 101;
    min-width: 200px;
    max-width: 300px;
} 
.tour-banner>div{
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    background-color: darkgray;
    border-radius: 3px;
}
@media only screen and (max-width: 920px) {
 .tour-banner>div{
        top: -18px;
        right: 2px;
    }
}