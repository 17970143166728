.container-md {
    max-width: 1200px;
  }
.overlay {
    position:absolute;
    width:100%;
    height:100%;
    z-index: -1;
    @apply inset-0;
    background-color: rgba(0,0,0,0.5);
    
}
.z-3{
  z-index: 3;
}
.btn-play {
    width:100px;
    height:100px;
    background-color: rgba(255,255,255,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    color:white;
}
.btn-play svg {
  font-size: 70px;
}
.btn-primary {
    width:188px;
    padding:10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('./assets/home/btn-primary.png');
    background-size:100% 100%;
    color:rgba(44, 27, 5, 1);
    font-family: 'Titillium Web', sans-serif;
    position:relative;
}
  .btn-primary_hover {
    width:188px !important;
    display: block;
    
    height:100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    transition: all .15s;
  }
  .btn-primary:hover .btn-primary_hover{
    transform: translate(5px, 5px);
  }
  .btn-primary:active .btn-primary_hover {
    transform: translate(3px, 3px);
  }
  .btn-primary_hover img {
      width:100%;
      height:100%;
  }
  .btn-secondary {
    width:188px;
    padding:10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('./assets/home/btn-secondary.png');
    background-size:100% 100%;
    color: #DECFC0;
    font-family: 'Titillium Web', sans-serif;
    position:relative;
  }
  .btn-secondary a{
    padding: 10px 20px;
    display: block ;
  }
  .btn-secondary:active {
    transform: translateY(0px)
  }
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @media only screen and (max-width: 560px) {
    .btn-primary {
      width:120px;
      padding:8px 0px;
      font-size: 14px;
      position:relative;
    }
    .btn-primary_hover {
      width:120px !important;
      top:0;
      left:0;
      z-index: -1;
      transition: all .15s;
    }
    .btn-secondary {
      width:120px;
      padding:8px 0px;
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
    background-color: black;
  }
  
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #E3BC5B; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e2b43f; 
  }
  