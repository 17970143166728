.loading-container{
    width: 95%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2A2A2D;
    border-radius: 6px;
    padding: 0px 24px;
    padding-bottom: 24px;
}
.loading-container .loading-gift{
    background-color: #2A2A2D;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
}
.loading-container .loading-info{
    margin-top: 70px;

}
.loading-container .loading__txhash{
    background-color: rgba(255,255,255,0.4);
    padding: 5px 10px;
    border-radius: 6px;
    max-width: 100%;
}
.loading-container .loading__txhash .tx_hash{
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

}
.loading-container .loading__txhash .loading__inform{
    position: absolute;
    top: -31px;
    left: 216px;
    display: none;
    font-size: 12px;
    clip-path: polygon(100% 0, 100% 88%, 86% 88%, 83% 100%, 66% 88%, 0 88%, 0 0);
    background-color: rgba(0,0,0,0.6);
    padding: 5px 10px;
    border-radius: 4px;
}
.loading-container .loading__txhash:hover .loading__inform{
    display: block;
}

.loading-container .logo {
  width: 200px;
  opacity: 0.6;
}
.loading-container .loading_status >svg{
  font-size: 40px;
}
.loading-container .loading__success{
  color: rgb(37, 151, 37);
}
.loading-container .loading__error{
  color:rgb(221, 16, 16);
}

.loading-container .btn-close{
  position: absolute;
  right: 24px;
  top: 24px;
  padding: 8px;
}




/* Loading gif */
.loading-container .loading-gift .lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.loading-container .loading-gift .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loading-container .loading-gift .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-container .loading-gift .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-container .loading-gift .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}