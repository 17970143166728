.footer-section {
    background: #E7E1D9;
    width:100%;
    min-height: 100vh;
}
.footer__partner {
    height:550px;
    background-color: #130C09;
    color: #DECFC0;
    font-size: 40px;
    font-weight: 700;
    font-family: 'Chakra Petch';
    letter-spacing: 15px;
    text-transform: uppercase;
    position:relative;
    width:100%;
}
.footer__partner .coming {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: 5px;
}
.footer__partner .title {
    /* transform: translateY(-100px); */
    margin-top: 80px;
}
.footer__partner .border__img {
    position:absolute;
    bottom: 0;
    left:0;
    width:100%;
    transform: translateY(50%);
}
.footer__partner .partner {
    /* background-image: url('../../../../assets/home/bg__partner__1.png'); */
    width:100%;
    display: flex;
    justify-content: center;
    bottom: -2%;
    left:50%;
    transform: translate(-50%,50%);
    z-index: 10;
    position: absolute;
    overflow-y: hidden;
}

.footer__partner .partner >img{
    width: 91%;
    max-width: 1280px;
    height: auto;
}
.footer__partner .partner .partner__img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78%;
    height: 78%;
    overflow-y: visible;
    overflow-x: hidden;
}

.footer-question {
    margin-top:350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #2C1B05;
}
.footer-question .icon {
    transition: all .3s;
}
.footer-question .questions__container {
    margin-top:50px;
    padding: 10px 0px;
    border-bottom: 1px solid #DECFC0;
    width: 100%;
    transition: all .3s;
    overflow: hidden;
    height:40px;
    cursor: pointer;
    font-size:16px;
}
.footer-question .questions__container .question__content {
  color:#352208;
  font-size: 14px;
}
.footer-question .questions__container .question__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
}
.footer-question .title__container  {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    align-items: flex-end;
}
.footer-question .title__container .title {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Chakra Petch';
    letter-spacing: 10px;
    line-height: 40px;
}
.footer-question .title__container .title__expand {
    color:#5B4B37;
}
.footer-question .footer__characters {
    
    margin-top:150px;
    position:relative;
    overflow: hidden;
    width:100%;
}
.footer-question .footer__characters .bg__character {
    width:100%;
    height:250px;
    
    
}
.footer-question .footer__characters .character__content {
    background: url('../../../../assets/home/footer-character__overlay.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    height:100%;
    left:50%;
    transform: translateX(-50%);
    top:0px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    
}
.footer-question .footer__characters .character__content .title{ 
    font-size: 40px;
    font-weight:600;
    font-family: 'Chakra Petch';
    color: #FFEFD8;
    text-align: center;
}

.footer-social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:100px 20px;
    box-sizing: border-box;
    width:100%;
    max-width: 1580px;
}
.footer-social__container {
   display: flex;
   align-items: center;
   flex-direction: row;
   justify-content: center;
   background: linear-gradient(180deg, rgba(231,225,217,1) 0%, rgba(231,225,217,0) 100%),url('../../../../assets/home/bg__footer.jpeg');
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   position:relative;
   padding-top:30px;
}
.footer-social__container .footer__border {
    position: absolute;
    top:0;
    left:0;
    transform:translateY(-40%);
    width: 100%;
}
.footer-social .part.part__1 {
    flex:1.2;
}
.footer-social .part.part__4 {
    flex:0.8;
}
.footer-social .part {
    flex:1;
    display: flex;
    flex-direction: column;
}
.footer-social .part .title {
    font-size:20px;
    font-weight: 600;

}
.footer-social .container-social__2 {
    flex:1.8;
}
.footer-social .part.part__1 .content  {
    margin-top:10px;
}
.footer-social .part .content {
    color: #5B4B37;
    font-size:14px;
    margin-top:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}
.footer-social .part .content img {
    width:20px;
    margin-right:15px;

}
.footer-social .part .logo {
    width: 200px;
}
 /* AS SOON AS  */
 .as-seen-on-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e7e1d9;
    margin-top: 136px;
    width: 100%;
    
  }
  .as-seen-on-container .title {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    /* identical to box height, or 130% */
    text-align: center;
    letter-spacing: 0.4em;

    color: #2c1b05;
    margin-bottom: 24px;
  }
 
  .as-seen-on-container .logo-wrapper {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .as-seen-on-container .imgback{
    width: 100%;
    height: 100%;
  }
  .as-seen-on-container  .logos {
      max-width: 1280px;
    /* top: 100px; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    /* width: 78%;
    height: 78%;
   */
    display: grid;
    grid-template-columns: auto auto auto;
  
    row-gap: 52px;
    column-gap: 84px;
  }
  .as-seen-on-container .item{
      cursor: pointer;
  }
@media only screen and (max-width: 1280px) {
    .footer__partner {
        padding: 0px 20px;
        height: 510px;
    }
    /* .footer__partner .partner {
        width: 75%;;
    } */
    .footer-question {
        padding:0px 20px;
        margin-top: 300px;
    }
    .as-seen-on-container .logos{
        width: 80%;

    }
}
@media only screen and (max-width: 1100px) {
    .footer__partner {
        padding: 0px 20px;
        height: 480px;
    }
    /* .footer__partner .partner {
        width: 75%;;
    } */
    .footer-question {
        padding:0px 20px;
        margin-top: 280px;
    }
    .as-seen-on-container .logos{
        width: 70%;

    }
   
  
}
@media only screen and (max-width: 960px) {
    .footer__partner {
        height: 440px;
    }
    .footer-question {
        margin-top: 250px;
    }
    .as-seen-on-container .logos{
        width: 70%;
        row-gap: 20px;
    }
    .as-seen-on-container .title{
        font-size: 30px;    }

}
@media only screen and (max-width: 800px) {
    .footer__partner {
        height: 390px;
    }
    .footer-question {
        margin-top: 210px;
    }

}
@media only screen and (max-width: 750px) {
    /* .footer__partner .title {
        transform: translate(0);
    } */
    .footer__partner {
        height: 240px;
    }
    .footer-question {
        margin-top: 190px;
    }
    
    .footer-question .questions__container {
        margin-top: 30px;
    }
    .footer-question .footer__characters {
        margin-top: 100px;
    }
    .footer-question .footer__characters .character__content .glitch{ 
        font-size: 22px;
    }
    .footer-question .footer__characters .bg__character {
        height:200px
    }
    .as-seen-on-container .logos{
       width: 70%;
    }
    .as-seen-on-container .title{
        font-size: 20px;    }
}
@media only screen and (max-width: 650px) {
    .footer__partner {
        height: 340px;
    }
    .footer-question {
        margin-top: 170px;
    }

}
@media only screen and (max-width: 560px) {
    .footer-question .footer__characters .character__content .title{
        font-size: 20px;
    }
    .footer-question .footer__characters .bg__character {
    }
    .footer__partner .title {
        text-align: center;
        font-size: 30px;
        letter-spacing: 10px;
        /* transform: translate(0px,0px); */
    }
    .footer__partner {
        height: 300px;
    }
    .footer-question {
        margin-top: 150px;
    }
    .footer-social {
        flex-direction: column;
    }
    .footer-social .part {
        margin-top: 10px;
    }
    .footer-social .part .content {
        margin-top: 10px;
    }
    .as-seen-on-container .logos{
        width: 90%;
        row-gap: 10px;

    }
}
@media only screen and (max-width: 460px){
    .footer__partner {
        height: 260px;
    }
    .footer-question {
        margin-top: 110px;
    }
    .footer__partner .partner >img{
        width: 90%;
    }
}
@media only screen and (max-width: 360px){
    .footer__partner {
        height: 250px;
    }
    .footer-question {
        margin-top: 70px;
    }
}

.glitch {
    font-size: 40px;
    font-weight:600;
    font-family: 'Chakra Petch';
    color: #FFEFD8;
    text-align: center;
    position: relative;
    letter-spacing:4px;
   /* Animation provies a slight random skew. Check bottom of doc for more information on how to random skew. */
    animation: glitch-skew 1s infinite linear alternate-reverse;
}
.glitch::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
   /* Creates an initial clip for our glitch. This works in a typical top,right,bottom,left fashion and creates a mask to only show a certain part of the glitch at a time. */
    clip: rect(44px, 450px, 56px, 0);
   /* Runs our glitch-anim defined below to run in a 5s loop, infinitely, with an alternating animation to keep things fresh. */
    animation: glitch-anim 5s infinite linear alternate-reverse;
}
.glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    animation: glitch-anim2 1s infinite linear alternate-reverse;
}
/* Creates an animation with 20 steaps. For each step, it calculates a percentage for the specific step. It then generates a random clip box to be used for the random glitch effect. Also adds a very subtle skew to change the 'thickness' of the glitch.*/
@keyframes glitch-anim {
    0% {
        clip: rect(51px, 9999px, 31px, 0);
        transform: skew(0.26deg);
   }
    5% {
        clip: rect(26px, 9999px, 98px, 0);
        transform: skew(0.83deg);
   }
    10% {
        clip: rect(90px, 9999px, 12px, 0);
        transform: skew(0.81deg);
   }
    15% {
        clip: rect(41px, 9999px, 59px, 0);
        transform: skew(0.32deg);
   }
    20% {
        clip: rect(42px, 9999px, 23px, 0);
        transform: skew(0.04deg);
   }
    25% {
        clip: rect(99px, 9999px, 1px, 0);
        transform: skew(0.04deg);
   }
    30% {
        clip: rect(40px, 9999px, 65px, 0);
        transform: skew(0.43deg);
   }
    35% {
        clip: rect(50px, 9999px, 51px, 0);
        transform: skew(0.85deg);
   }
    40% {
        clip: rect(74px, 9999px, 62px, 0);
        transform: skew(0.31deg);
   }
    45% {
        clip: rect(43px, 9999px, 66px, 0);
        transform: skew(0.3deg);
   }
    50% {
        clip: rect(68px, 9999px, 18px, 0);
        transform: skew(0.33deg);
   }
    55% {
        clip: rect(97px, 9999px, 61px, 0);
        transform: skew(0.33deg);
   }
    60% {
        clip: rect(21px, 9999px, 82px, 0);
        transform: skew(0.69deg);
   }
    65% {
        clip: rect(61px, 9999px, 7px, 0);
        transform: skew(0.21deg);
   }
    70% {
        clip: rect(50px, 9999px, 72px, 0);
        transform: skew(0.65deg);
   }
    75% {
        clip: rect(40px, 9999px, 15px, 0);
        transform: skew(0.12deg);
   }
    80% {
        clip: rect(32px, 9999px, 95px, 0);
        transform: skew(0.19deg);
   }
    85% {
        clip: rect(62px, 9999px, 69px, 0);
        transform: skew(0.9deg);
   }
    90% {
        clip: rect(83px, 9999px, 72px, 0);
        transform: skew(0.44deg);
   }
    95% {
        clip: rect(42px, 9999px, 72px, 0);
        transform: skew(0.35deg);
   }
    100% {
        clip: rect(54px, 9999px, 66px, 0);
        transform: skew(0.21deg);
   }
}
@keyframes glitch-anim2 {
    0% {
        clip: rect(44px, 9999px, 96px, 0);
        transform: skew(0.83deg);
   }
    5% {
        clip: rect(16px, 9999px, 72px, 0);
        transform: skew(0.36deg);
   }
    10% {
        clip: rect(50px, 9999px, 6px, 0);
        transform: skew(0.18deg);
   }
    15% {
        clip: rect(3px, 9999px, 66px, 0);
        transform: skew(0.14deg);
   }
    20% {
        clip: rect(46px, 9999px, 26px, 0);
        transform: skew(0.87deg);
   }
    25% {
        clip: rect(54px, 9999px, 5px, 0);
        transform: skew(0.6deg);
   }
    30% {
        clip: rect(43px, 9999px, 19px, 0);
        transform: skew(0.54deg);
   }
    35% {
        clip: rect(51px, 9999px, 64px, 0);
        transform: skew(0.76deg);
   }
    40% {
        clip: rect(81px, 9999px, 67px, 0);
        transform: skew(0.03deg);
   }
    45% {
        clip: rect(60px, 9999px, 4px, 0);
        transform: skew(0.54deg);
   }
    50% {
        clip: rect(21px, 9999px, 69px, 0);
        transform: skew(0.65deg);
   }
    55% {
        clip: rect(3px, 9999px, 41px, 0);
        transform: skew(0.41deg);
   }
    60% {
        clip: rect(68px, 9999px, 18px, 0);
        transform: skew(0.52deg);
   }
    65% {
        clip: rect(43px, 9999px, 57px, 0);
        transform: skew(0.87deg);
   }
    70% {
        clip: rect(50px, 9999px, 21px, 0);
        transform: skew(0.18deg);
   }
    75% {
        clip: rect(4px, 9999px, 58px, 0);
        transform: skew(0.29deg);
   }
    80% {
        clip: rect(92px, 9999px, 13px, 0);
        transform: skew(0.23deg);
   }
    85% {
        clip: rect(64px, 9999px, 79px, 0);
        transform: skew(0.19deg);
   }
    90% {
        clip: rect(57px, 9999px, 90px, 0);
        transform: skew(0.74deg);
   }
    95% {
        clip: rect(43px, 9999px, 58px, 0);
        transform: skew(0.14deg);
   }
    100% {
        clip: rect(77px, 9999px, 43px, 0);
        transform: skew(0.45deg);
   }
}
@keyframes glitch-skew {
    0% {
        transform: skew(1deg);
   }
    10% {
        transform: skew(2deg);
   }
    20% {
        transform: skew(-2deg);
   }
    30% {
        transform: skew(5deg);
   }
    40% {
        transform: skew(3deg);
   }
    50% {
        transform: skew(3deg);
   }
    60% {
        transform: skew(3deg);
   }
    70% {
        transform: skew(1deg);
   }
    80% {
        transform: skew(-1deg);
   }
    90% {
        transform: skew(3deg);
   }
    100% {
        transform: skew(-4deg);
   }
}

  