.buy-overlay {
    color: #E7E1D9;
    width:100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    -moz-user-select: none ;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.buy-overlay .buy-form {
    background: url('../../../../assets/sale/bg__overlay.png');
    padding: 30px;
    width:430px;
    border-radius:10px;
    position:relative;
    background-position: center;
    background-size: cover;
}
.buy-overlay .buy-form .btn {
    width:120px;
}
.buy-overlay .buy-form .title {
    width:320px;
    background: url('../../../../assets/sale/bg__title__overlay.png');
    background-position: center;
    background-size: 110% 152%;
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top:0;
    left:50%;
    transform: translate(-50%, -50%);
    padding-top: 14px;
    padding-bottom: 20px;
    padding-right: 5px;
    padding-left: 5px;
}
.buy-overlay .buy-form  .moreinfo span{
    color: #E7C060;
    margin-left: 3px;
}
.buy-overlay .buy-form .title span {
    /* transform: translateY(-5px); */
    font-size: 20px;
    font-weight: 700;
    font-family: 'Chakra Petch';
    color:white;
    letter-spacing: 5px;
}
.buy-overlay .filter-search {
    border: solid 1px #51555A;
    padding: 10px 20px 10px 12px;
    border-radius: 4px;
}
.buy-overlay  .filter-search input{
    width: 80%;
    background-color: transparent;
    border: none;
}
.buy-overlay .filter-search input:focus{
    border: none;
    outline: none;
}
.buy-overlay.filter-search input::-webkit-input-placeholder { /* Edge */
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51555A;
  }
  
  .buy-overlay .filter-search input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51555A;
  }
  
  .buy-overlay.filter-search input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51555A;
  }
  .buy-overlay .inform{
      color: red;
  }
  .buy-overlay .filter-search input::-webkit-outer-spin-button,
  .buy-overlay .filter-search input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.buy-overlay .container-icon {
    background-color: #2a213d;
    border: solid 1px #51555A;
}
.buy-overlay .btn-max{
    padding-left: 6px;
    padding-right: 6px;
    background-color: #2a213d;
    border: solid 1px #51555A;
    border-radius: 4px;
}

/* Firefox */
.buy-overlay .filter-search input[type=number] {
  -moz-appearance: textfield;
}
@media only screen and (max-width: 560px){
    .buy-overlay .buy-form{
        width: 85%;
    }
    .buy-overlay .buy-form .title{
        width: 80%;
    }
    .buy-overlay .buy-form .title >span{
        font-size: 16px;
    }
}