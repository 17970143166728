.account-detail {
    font-family: 'Montserrat'
}
.account-detail .form-detail {
    background-color: #2A2A2D;
    width:450px;
}
.account-detail .form-detail .form-btn-list{
    display: flex;
    margin-top: 32px;
    font-size: 14px;
    line-height: 20px;
}
.account-detail .form-detail .online-icon {
    width:8px;
    height:8px;
    background-color: #31b554;
    border-radius: 50%;
    box-shadow: 0px 0px 22px 5px rgba(49,181,84,0.84);
}
.account-detail .logo-binance {
    width:25px;
    height:25px;
}
.account-detail .balance-info {
    background: url('../../../assets/binance-bg.svg'),#1A1A1B ;
    background-repeat: no-repeat;
    background-position:90% 0px;
    border: 1px solid #acb0ad
}
.account-detail .btn-account:active {
    @apply bg-gray-500
}
@media only screen and (max-width: 500px){
    .account-detail .form-detail .form-btn-list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
        font-size: 14px;
        margin-top: 26px;
    }
    .account-detail .form-detail .form-detail__account-address > div,.account-detail .form-detail .form-detail__account-address > span{
        font-size: 18px;
    }
    .account-detail .form-detail .form-btn-list div, .account-detail .form-detail .form-btn-list div a{
        margin-right: 0px;
    }
    .account-detail .form-detail {
        width: 100%;
        margin-left: 20px;
        margin-right: 20px;
        padding: 32px 28px;
        margin-top: 10px;
    }
    .account-detail .balance-info{
        padding: 10px;
        margin-top: 14px;
    }
    .account-detail .form-detail .form-btn-list > div{
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
@media only screen and (max-width: 400px){
    .account-detail .form-detail .form-btn-list > div{
        font-size: 12px;
    }
    .account-detail .form-detail .form-detail__account-address > div,.account-detail .form-detail .form-detail__account-address > span{
        font-size: 16px;
    }
    .account-detail .form-detail .online-icon{
        width: 6px;
        height: 6px;
    }
    .account-detail .logo-binance{
        width: 20px;
        height: 20px;
    }
    .account-detail .balance-info >div >span{
        font-size: 16px;
    }
    .account-detail .form-detail .online-icon{
        margin-right: 8px;
    }
    .account-detail .form-detail .form-btn-list .form-btn-icon{
        margin-bottom: 4px;
    }
}